import {
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import SingleDropzone from '../components/dropzone/SingleDropzone';
import { uploadImage } from '../utils/uploadToFirebase';
import { startLoading, stopLoading } from '../redux/features/authSlice';
import { createANewDealer } from '../api/dealerApi';
import { StateOptions, nepalDistricts } from '../data/options';

export default function NewDealer() {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [state, setState] = useState('Select a State');
  const [district, setDistrict] = useState('Select a District');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [hasPNY, setHasPNY] = useState(true);
  const [hasHuntkey, setHasHuntkey] = useState(true);
  const [hasHavit, setHasHavit] = useState(true);

  const { mutate: addMutate } = useMutation(createANewDealer, {
    onSuccess: () => {
      dispatch(stopLoading());
      toast.success('Dealer added successfully');

      setName('');
      setEmail('');
      setPhone('');
      setState('Select a State');
      setDistrict('Select a Discrict');
      setCity('');
      setAddress('');
    },
    onError: () => {
      dispatch(stopLoading());
      toast.error('Error adding dealer');
    },
  });

  const submitDealer = (e) => {
    e.preventDefault();
    addMutate({ name, email, phone, state, district, city, address, hasPNY, hasHuntkey, hasHavit });
  };

  const InputFields = [
    { label: 'Name', value: name, onChange: setName },
    { label: 'Email', value: email, onChange: setEmail },
    { label: 'Phone', value: phone, onChange: setPhone },
  ];

  const InputFieldSecondGroup = [
    { label: 'City', value: city, onChange: setCity },
    { label: 'Address', value: address, onChange: setAddress },
  ];

  return (
    <>
      <Helmet>
        <title>New Dealer | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" gutterBottom>
          Create New Dealer
        </Typography>

        <FormControl fullWidth>
          <Stack spacing={4}>
            {InputFields.map((input, index) => (
              <TextField
                key={index}
                label={input.label}
                value={input.value}
                onChange={(e) => input.onChange(e.target.value)}
              />
            ))}
            <Select
              labelId="state"
              id="state"
              label="State"
              defaultValue="Select a State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <MenuItem disabled value="Select a State">
                Select a State
              </MenuItem>

              {StateOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            <Select
              labelId="district"
              id="district"
              label="District"
              defaultValue="Select a District"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            >
              <MenuItem disabled value="Select a District">
                Select a District
              </MenuItem>

              {nepalDistricts.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>

            {InputFieldSecondGroup.map((input, index) => (
              <TextField
                key={index}
                label={input.label}
                value={input.value}
                onChange={(e) => input.onChange(e.target.value)}
              />
            ))}

            {/* <Stack direction="row" alignItems={'center'}>
              <Checkbox />
              <Typography variant="body2" sx={{ fontSize: 16 }}>
                Has PNY
              </Typography>
            </Stack> */}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={hasPNY} onChange={(e) => setHasPNY(e.target.checked)} name="hasPNY" />}
                label="Has PNY"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={hasHuntkey} onChange={(e) => setHasHuntkey(e.target.checked)} name="hasHuntkey" />
                }
                label="Has Huntkey"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={hasHavit} onChange={(e) => setHasHavit(e.target.checked)} name="hasHavit" />
                }
                label="Has Havit"
              />
            </FormGroup>

            <div className="flex mt-4">
              <Button
                variant="contained"
                onClick={(e) => {
                  submitDealer(e);
                }}
              >
                Submit
              </Button>
            </div>
          </Stack>
        </FormControl>
      </Container>
      <Toaster />
    </>
  );
}
