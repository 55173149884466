import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  isFetching: false,
  error: false,
  isLoggedIn: false,
  isLoading: false,
};

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authStart: (state) => {
      state.isFetching = true;
    },
    authSuccess: (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
      state.isLoggedIn = true;
      // window.location.reload();
    },
    authFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    updateAccessToken: (state, action) => {
      state.user = { ...state.user, accessToken: action.payload.accessToken };
    },
    logout: (state) => {
      state.user = { _id: '', name: '', email: '', userType: '', accessToken: '', refreshToken: '', avatar: '' };
      state.isLoggedIn = false;
      window.location.reload();
    },
    startLoading: (state) => {
      state.isLoading = true;
      document.body.style.overflow = 'hidden';
    },
    stopLoading: (state) => {
      state.isLoading = false;
      document.body.style.overflow = 'auto';
    },
  },
});

export const { authStart, authSuccess, authFailure, updateAccessToken, logout, startLoading, stopLoading } =
  authSlice.actions;
export default authSlice.reducer;
