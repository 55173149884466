import { axiosInstance } from '../configs/axiosConfig';

export const getAllHeroImages = async () => {
  const res = await axiosInstance.get('/heroImages');
  return res.data;
};

export const createAHeroImage = async ({ title, description, imageUrl }) => {
  await axiosInstance.post('/heroImages/', { title, description, imageUrl });
};

export const getASingleHeroImage = async (id) => {
  const res = await axiosInstance.get(`/heroImages/${id}`);
  return res.data;
};

export const deleteAHeroImage = async (id) => {
  const res = await axiosInstance.delete(`/heroImages/${id}`);
};
