import { Button, Container, FormControl, Stack, TextField, Typography } from '@mui/material';

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import SingleDropzone from '../components/dropzone/SingleDropzone';
import { uploadImage } from '../utils/uploadToFirebase';
import { startLoading, stopLoading } from '../redux/features/authSlice';
import { createAHeroImage } from '../api/heroImageApi';

export default function NewHeroImage() {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [imageFile, setImageFile] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [description, setDescription] = useState('');

  const { mutate: addMutate } = useMutation(createAHeroImage, {
    onSuccess: () => {
      dispatch(stopLoading());
      toast.success('Hero Image added successfully');
      setTitle('');
      setImageFile();
      setImagePreview();
      setDescription('');
    },
    onError: () => {
      dispatch(stopLoading());
      toast.error('Error adding hero image');
    },
  });

  const submitHeroImage = async (e) => {
    e.preventDefault();
    dispatch(startLoading());
    uploadImage({ items: imageFile }).then((link) => {
      createPostRequest(link);
    });
  };

  const createPostRequest = (link) => {
    addMutate({ imageUrl: link, title, description });
  };

  return (
    <>
      <Helmet>
        <title>New Hero Image | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" gutterBottom>
          Create New Hero Image
        </Typography>

        <FormControl fullWidth>
          <Stack spacing={4}>
            <TextField label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
            <SingleDropzone
              setImageFile={setImageFile}
              setImagePreview={setImagePreview}
              imageFile={imageFile}
              imagePreview={imagePreview}
              title={'Hero Image'}
            />
            <TextField
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
            />
            <div className="flex mt-4">
              <Button
                variant="contained"
                onClick={(e) => {
                  submitHeroImage(e);
                }}
              >
                Submit
              </Button>
            </div>
          </Stack>
        </FormControl>
      </Container>
      <Toaster />
    </>
  );
}
