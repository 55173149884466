import { axiosInstance } from '../configs/axiosConfig';

export const getAllTestimonials = async () => {
  const res = await axiosInstance.get('/testimonials');
  return res.data;
};

export const createATestimonial = async ({ avatar, name, designation, testimonial }) => {
  await axiosInstance.post('/testimonials/', { avatar, name, designation, testimonial });
};

export const getASingleTestimonial = async (id) => {
  const res = await axiosInstance.get(`/testimonials/${id}`);
  return res.data;
};

export const deleteATestimonial = async (id) => {
  const res = await axiosInstance.delete(`/testimonials/${id}`);
};
