import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Cancel } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import SingleDropzone from '../dropzone/SingleDropzone';
import MultipleDropzone from '../dropzone/MultipleDropzone';
import ListInput from '../listinput/ListInput';
import { createNewCategory, getAllCatgories } from '../../api/categoryApi';
import { createNewBrand, getAllBrands } from '../../api/brandApi';
import { getASingleSpec, getAllSpecs } from '../../api/specApi';
import TableInput from '../table-input/TableInput';
import { updateAProduct } from '../../api/productApi';
import { startLoading, stopLoading } from '../../redux/features/authSlice';
import { uploadFiles, uploadImage } from '../../utils/uploadToFirebase';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditProduct({ product, setActive }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [category, setCategory] = useState(product.category);
  const [title, setTitle] = useState(product.title);
  const [basePrice, setBasePrice] = useState(product.basePrice);
  const [discount, setDiscount] = useState(product.discount);
  const [slug, setSlug] = useState(product.slug);
  const [metaDesc, setMetaDesc] = useState(product.metaDesc);
  const [descPoints, setDescPoints] = useState(product.descPoints);
  //   const [specsArray, setSpecsArray] = useState({});
  const [brand, setBrand] = useState(product.brand);
  const [searchTags, setSearchTags] = useState(product.searchTags);
  const [stockAmount, setStockAmount] = useState(product.stockAmount);
  const [coverImageFile, setCoverImageFile] = useState(product.coverImage);
  const [coverImagePreview, setCoverImagePreview] = useState(product.coverImage);
  const [imageVariationFiles, setImageVariationFiles] = useState(product.imgVariations);
  const [imageVariationPreviews, setImageVariationPreviews] = useState(product.imgVariations);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [specTitle, setSpecTitle] = useState('Select a Specification Template');
  const [coverImageLink, setCoverImageLink] = useState(product.coverImage);
  const [imageVariationLinks, setImageVariationLinks] = useState(product.imgVariations);
  const [specs, setSpecs] = useState(
    product.specs
      ? product.specs
      : [
          { attribute: '', value: '' },
          // Add more initial rows if needed
        ]
  );

  // const [specTitle, useSpecTitle] = useState()

  const { data: categoryOptions } = useQuery(['category'], getAllCatgories);
  const { data: brandOptions } = useQuery(['brand'], getAllBrands);
  const { data: specOptions } = useQuery(['spec'], getAllSpecs);

  const { data: specsData } = useQuery(['specs-attribute', specTitle], () => getASingleSpec(specTitle), {
    enabled: specTitle !== 'Select a Specification Template',
  });
  const InputFields = [
    {
      name: 'title',
      value: title,
      setValue: setTitle,
      label: 'Product Name',
      type: 'text',
      labelId: 'product-title',
    },
    {
      name: 'slug',
      value: slug,
      setValue: setSlug,
      label: 'Product Slug',
      type: 'text',
      labelId: 'product',
    },
    {
      name: 'basePrice',
      value: basePrice,
      setValue: setBasePrice,
      label: 'Product Price',
      type: 'number',
      labelId: 'product-price',
    },
    {
      name: 'discount',
      value: discount,
      setValue: setDiscount,
      label: 'Discount',
      type: 'number',
      labelId: 'product-price',
    },
    {
      name: 'metaDesc',
      value: metaDesc,
      setValue: setMetaDesc,
      label: 'Meta Description',
      type: 'text',
      labelId: 'product-meta',
    },
    {
      name: 'stock',
      value: stockAmount,
      setValue: setStockAmount,
      label: `Product's stock amount`,
      type: 'number',
      labelId: 'product-stock',
    },
  ];

  const { mutate: updateMutate } = useMutation(updateAProduct, {
    onSuccess: () => {
      dispatch(stopLoading());
      toast.success('Sucessfully Created the product');
      //   setCategory('Select a Category');
      //   setTitle('');
      //   setSlug('');
      //   setBasePrice(0);
      //   setDiscount(0);
      //   setMetaDesc('');
      //   setStockAmount(0);
      //   setBrand('Select a Brand');
      //   setSpecTitle('Select a Specification Template');
      //   setSearchTags([]);
      //   setDescPoints([]);
      //   setSpe({});
      setActive(false);
      queryClient.invalidateQueries(['single-spec', product._id]);
    },
    onError: () => {
      console.log('error');
      dispatch(stopLoading());
      toast.error('Something went wrong please try again!');
    },
  });

  const haveImageBeenUpdated = (prev, curr) => {
    if (prev.length !== curr.length) {
      return true;
    }

    // Check if any individual image URL has changed.
    for (let i = 0; i < curr.length; i += 1) {
      if (prev[i] !== curr[i]) {
        return true;
      }
    }

    return false;
  };

  const handleCategoryClose = () => {
    setCategoryOpen(false);
  };

  const handleBrandClose = () => {
    setBrandOpen(false);
  };

  // const updateImgVariations = async () => {
  //   await uploadFiles(imageVariationFiles).then(async (links) => {
  //     setImageVariationLinks(links);
  //   });
  // };

  async function updateImagesAndUploadToDatabase(previousImages, currentImages) {
    // Extract strings from the current images (ignoring File objects)
    const currentImageLinks = currentImages.filter((image) => typeof image === 'string');

    // Extract File objects from the current images
    const newImages = currentImages.filter((image) => image instanceof File);

    if (newImages.length > 0) {
      // Upload new images to the database using the uploadToDatabase function
      const uploadedImageLinks = await uploadFiles(newImages);

      // Combine the unchanged image links with the new ones
      const updatedImageLinks = currentImageLinks.concat(uploadedImageLinks);
      setImageVariationLinks(updatedImageLinks);
      return updatedImageLinks;
    }
    return currentImageLinks;
  }

  const handleProductUpdate = async () => {
    dispatch(startLoading());
    const newArray = specs.map(({ _id, ...rest }) => rest);
    let currCover = coverImageLink;
    let currentLinks = imageVariationLinks;

    const updated = haveImageBeenUpdated(imageVariationLinks, imageVariationFiles);

    if (updated) {
      currentLinks = await updateImagesAndUploadToDatabase(imageVariationLinks, imageVariationFiles);
    }
    if (typeof coverImageFile === 'object') {
      currCover = await uploadImage({ items: coverImageFile });
    }
    updateMutate({
      productId: product._id,
      title,
      coverImage: currCover,
      imgVariations: currentLinks,
      slug,
      metaDesc,
      basePrice,
      discount,
      descPoints,
      specs: newArray,
      brand,
      searchTags,
      category,
      stockAmount,
    });
  };

  useEffect(() => {
    if (specsData) {
      setSpecs(specsData.specs);
    }
  }, [specsData]);

  return (
    <>
      <Container>
        <div className="w-full">
          <Stack spacing={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      console.log('This is good');
                    }}
                  />
                }
                label="Set this product as a featured product."
              />
            </FormGroup>
            <Stack spacing={2}>
              {/* <InputLabel id="product-category">Category of Product</InputLabel> */}
              <Select
                labelId="product-category"
                id="product-category"
                label="Age"
                value={category}
                defaultValue={product.category}
                onChange={(e) => {
                  if (e.target.value !== 'nochange') {
                    setCategory(e.target.value);
                  }
                }}
              >
                <MenuItem disabled value="Select a Category">
                  Select a Category
                </MenuItem>
                {categoryOptions?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value="nochange"
                  onClick={() => {
                    setCategoryOpen(true);
                  }}
                >
                  Create New Category
                </MenuItem>
              </Select>
            </Stack>
            {InputFields.map((item, index) => (
              <TextField
                label={item.label}
                name={item.name}
                value={item.value}
                type={item.type}
                onChange={(e) => item.setValue(e.target.value)}
                key={index}
              >
                {' '}
              </TextField>
            ))}
            <Stack spacing={2}>
              {/* <InputLabel id="product-brand">Brand of Product</InputLabel> */}
              <Select
                labelId="product-brand"
                id="product-brand"
                label="Brand"
                value={brand}
                defaultValue={'Select a Brand'}
                onChange={(e) => {
                  if (e.target.value !== 'nochange') {
                    setBrand(e.target.value);
                  }
                }}
              >
                <MenuItem disabled value="Select a Brand">
                  Select a Brand
                </MenuItem>
                {brandOptions?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value="nochange"
                  onClick={() => {
                    setBrandOpen(true);
                  }}
                >
                  Create New Brand
                </MenuItem>
              </Select>
            </Stack>

            <SingleDropzone
              setImageFile={setCoverImageFile}
              setImagePreview={setCoverImagePreview}
              imageFile={coverImageFile}
              imagePreview={coverImagePreview}
            />
            <MultipleDropzone
              setImageFiles={setImageVariationFiles}
              setImagePreviews={setImageVariationPreviews}
              imageFiles={imageVariationFiles}
              imagePreviews={imageVariationPreviews}
            />
            <ListInput label={'Description Points'} descPoints={descPoints} setDescPoints={setDescPoints} />

            {/* <InputLabel id="product-brand">Brand of Product</InputLabel> */}
            <Select
              labelId="product-spec"
              id="product-spec"
              label="Spec"
              value={specTitle}
              defaultValue={'Select a Specification Template'}
              onChange={(e) => {
                if (e.target.value !== 'nochange') {
                  //   setSpecsArray({});
                  setSpecTitle(e.target.value);
                }
              }}
            >
              <MenuItem disabled value="Select a Specification Template">
                Select a Specification Table
              </MenuItem>
              {specOptions?.map((item, index) => (
                <MenuItem value={item._id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
              <MenuItem
                value="nochange"
                onClick={() => {
                  navigate('/dashboard/specs');
                }}
              >
                Create New Spec Template
              </MenuItem>
            </Select>

            <TableInput label={'Table Input'} specs={specs} setSpecs={setSpecs} />

            <Autocomplete
              style={{ margin: '20px 0' }}
              multiple
              id="tags-outlined"
              options={['Laptop', 'Desktop', 'PC', 'IDK What']}
              defaultValue={product.searchTags}
              freeSolo
              autoSelect
              on
              renderTags={(value, { className, onDelete }) =>
                value.map((option, index) => (
                  <Chip
                    className={className}
                    data-tag-index={index} // This should work, but there's a bug in MUI Autocomplete
                    deleteIcon={
                      // And this is a workaround for that bug
                      <Cancel // from @materialui/icons
                        data-tag-index={index}
                      />
                    }
                    key={option}
                    label={option}
                    onDelete={onDelete}
                    tabIndex={-1}
                  />
                ))
              }
              onChange={(e) => {
                if (e.code === 'Backspace') {
                  setSearchTags((prevArray) => prevArray.slice(0, -1));
                } else {
                  setSearchTags((prev) => [...prev, e.target.value]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Tags" placeholder="Search Tags" value={searchTags} />
              )}
            />
          </Stack>
          <div className="mt-4">
            <Button
              variant="contained"
              onClick={() => {
                // submitProduct();
                handleProductUpdate();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        <NewCategoryDialog open={categoryOpen} handleClose={handleCategoryClose} />
        <NewBrandDialog open={brandOpen} handleClose={handleBrandClose} />
      </Container>
      <Toaster />
    </>
  );
}

function NewCategoryDialog({ open, handleClose }) {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const queryClient = useQueryClient();

  const { mutate: addCategoryMutate } = useMutation(createNewCategory, {
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries(['category']);
    },
  });

  const handleSubmit = () => {
    addCategoryMutate({ label, value });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-new-category"
    >
      <DialogTitle>{'Create a New Category'}</DialogTitle>
      <DialogContent id="alert-dialog-new-category">
        <Stack spacing={2} direction={'column'}>
          <TextField
            label={'Label for Category'}
            name={'label'}
            value={label}
            type={'text'}
            onChange={(e) => setLabel(e.target.value)}
            sx={{ mt: 1 }}
          >
            {' '}
          </TextField>
          <TextField
            label={'Value for Category'}
            name={'value'}
            value={value}
            type={'text'}
            onChange={(e) => setValue(e.target.value)}
          >
            {' '}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function NewBrandDialog({ open, handleClose }) {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const queryClient = useQueryClient();

  const { mutate: addBrandMutate } = useMutation(createNewBrand, {
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries(['brand']);
    },
  });

  const handleSubmit = () => {
    addBrandMutate({ label, value });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-new-category"
    >
      <DialogTitle>{'Create a New Brand'}</DialogTitle>
      <DialogContent id="alert-dialog-new-brand">
        <Stack spacing={2} direction={'column'}>
          <TextField
            label={'Label for Brand'}
            name={'label'}
            value={label}
            type={'text'}
            onChange={(e) => setLabel(e.target.value)}
            sx={{ mt: 1 }}
          >
            {' '}
          </TextField>
          <TextField
            label={'Value for Brand'}
            name={'value'}
            value={value}
            type={'text'}
            onChange={(e) => setValue(e.target.value)}
          >
            {' '}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
