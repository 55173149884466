import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';

export default function SingleDropzone({ setImageFile, setImagePreview, imageFile, imagePreview, title }) {
  const onDrop = (accepted) => {
    const acceptedObj = accepted[0];
    acceptedObj.timestamp = Date.now();
    setImageFile(acceptedObj);
    setImagePreview(URL.createObjectURL(acceptedObj));
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: { 'image/png': ['.png', '.jpg', '.jpeg', '.svg', '.webp'] },
    maxFiles: 1,
    noClick: true,
  });

  const removeImage = () => {
    setImageFile();
    setImagePreview();
  };

  return (
    <>
      <div className="text-[14px] font-[100]">{title}</div>
      <div
        {...getRootProps()}
        className="w-full h-[200px] bg-[#E4E4E4] rounded-xl text-fade flex items-center justify-center relative"
        style={{ border: '1px solid #00000025' }}
      >
        {imageFile && (
          <div className="absolute top-[10px] left-[50%]  w-[95%] p-[10px] -translate-x-[50%] rounded-lg cursor-default flex flex-wrap gap-2">
            <div className="w-28  flex items-center rounded  text-sm relative">
              <div className="w-20 h-20 relative">
                <img src={imagePreview} alt="" className="object-cover object-top w-full h-full rounded-lg" />
                <div
                  className="absolute -top-[6px] -right-[6px]"
                  onClick={() => {
                    removeImage();
                  }}
                  role="button"
                  tabIndex="0"
                  onKeyDown={() => {}}
                >
                  <div className="bg-[#ff0000] w-4 h-4 relative rounded-full cursor-pointer">
                    <div className="w-[2px] bg-white h-[12px] rounded-md rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]" />
                    <div className="w-[2px] bg-white h-[12px] rounded-md -rotate-45 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isDragActive ? (
          <p className="flex gap-2">Drop the files here ...</p>
        ) : (
          <div className="flex items-center justify-center flex-col gap-2">
            <div className="flex flex-col items-center ">
              <p>Drag and drop</p>
              <p>or</p>
              <Button onClick={open}>Click to upload photo</Button>
            </div>
          </div>
        )}
        <input {...getInputProps()} />
      </div>
    </>
  );
}
