import {
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getASingleSpec } from '../api/specApi';

export default function SingleSpec() {
  const location = useLocation();

  const title = location.pathname.split('/')[3].toUpperCase().replace('_', ' ');

  const { data: specs } = useQuery(['single-spec', location.state.id], () => getASingleSpec(location.state.id));
  console.log(specs);

  return (
    <>
      <Helmet>
        <title>{title} | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {/* {location.pathname.split('/')[3].charAt(0).toUpperCase() + location.pathname.split('/')[3].slice(1)} */}
          {specs?.title}
        </Typography>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Category:
          </Typography>
          <Typography variant="caption" sx={{ fontSize: 16 }}>
            {specs?.category}
          </Typography>
        </Stack>
        <div>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead className="bg-black">
                <TableRow>
                  <TableCell>Attributes</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {specs?.specs?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.attribute}</TableCell>
                    <TableCell>{item?.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </>
  );
}
