import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import LoginPage from './pages/LoginPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Loading from './components/loading/Loading';

// ----------------------------------------------------------------------

export default function App() {
  // const hasUser = useSelector((state) => state.auth.isLoggedIn);
  const { isLoading } = useSelector((state) => state.auth);
  // const isLoading = true;
  // console.log(hasUser);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <div className="w-full relative">
            <ScrollToTop />
            <StyledChart />
            <Router />

            {isLoading && <Loading />}
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
