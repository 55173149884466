import { useState } from 'react';
import { Button, FormControl, Stack, TextField } from '@mui/material';
import Iconify from '../iconify/Iconify';

export default function ListInput({ label, descPoints, setDescPoints }) {
  const [input, setInput] = useState('');
  return (
    <div>
      <div className="text-[14px] font-[100]">{label}</div>
      <div className="w-full bg-[#fefefe] rounded-xl text-fade flex items-center p-4">
        <div className="flex flex-col gap-2 w-full">
          <ol className="list-decimal px-4 mb-4" type="1">
            {descPoints.map((item, index) => (
              <li key={index}>
                <div className="flex gap-1">
                  {item}{' '}
                  <span>
                    <Iconify
                      icon="tabler:trash-filled"
                      className="text-[#ff0000] cursor-pointer"
                      onClick={() => {
                        setDescPoints((prev) => prev.filter((i) => descPoints.indexOf(i) !== index));
                      }}
                    />
                  </span>
                </div>
              </li>
            ))}
          </ol>
          <FormControl fullWidth>
            <div className="flex gap-2 w-full">
              <TextField
                // label={'Description Point'}
                className="flex-1"
                name={'desc'}
                value={input}
                type={'text'}
                onChange={(e) => setInput(e.target.value)}
              >
                {''}
              </TextField>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                // onClick={() => {
                //   navigate('/dashboard/new-product');
                // }}
                onClick={() => {
                  setDescPoints((prev) => [...prev, input]);
                  setInput('');
                }}
              >
                Add Point
              </Button>
            </div>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
