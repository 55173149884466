import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../configs/firebaseconfig';

function uploadFiles(items) {
  return new Promise((resolve, reject) => {
    const links = [];
    let index = 1;
    items?.forEach((i) => {
      const fileName = ` ${new Date().getTime()} file document ${i.name}`;
      const postRef = ref(storage, `/items/${fileName}`);
      const uploadTask = uploadBytesResumable(postRef, i);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress} % done`);
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
            default:
              break;
          }
        },
        (err) => {
          console.log(err);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            links.push(url);
            if (index === items.length) {
              resolve(links);
            }
            index += 1;
          });
        }
      );
    });
  });
}

function uploadImage({ items }) {
  return new Promise((resolve, reject) => {
    console.log(items);
    const fileName = ` ${new Date().getTime()} file document ${items.name}`;
    const postRef = ref(storage, `/items/${fileName}`);
    const uploadTask = uploadBytesResumable(postRef, items);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress} % done`);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          resolve(url);
        });
      }
    );
  });
}

export { uploadFiles, uploadImage };
