import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyA-Va5_RevgjV0daP56m3BUhuctvnlO5BQ',
  authDomain: 'iteam-999d4.firebaseapp.com',
  projectId: 'iteam-999d4',
  storageBucket: 'iteam-999d4.appspot.com',
  messagingSenderId: '9095084865',
  appId: '1:9095084865:web:9b10969b09491e05da3fc0',
  measurementId: 'G-KVSS0VF9QF',
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
