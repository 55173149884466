import { unverifiedInstance } from '../../configs/axiosConfig';
import { authFailure, authStart, authSuccess, logout } from '../features/authSlice';

export const login = async (dispatch, user) => {
  dispatch(authStart());
  try {
    const res = await unverifiedInstance.post('/auth/login', user);
    dispatch(authSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(authFailure());
    return err?.response?.status;
  }
};

export const tokenLogout = async (refreshToken, dispatch) => {
  await unverifiedInstance.post('/auth/logout', { token: refreshToken });
  dispatch(logout());
};
