import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

export default function Loading() {
  const { isLoading } = useSelector((state) => state.auth);
  // const isLoading = true;
  const visible = true;

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
      console.log(document.body.style.overflow);
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isLoading]);
  return (
    <div className="fixed w-full h-screen top-0 left-0 z-[10000] flex justify-center items-center bg-[#1e1e1e45] backdrop-blur-sm">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#2596be"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={visible}
      />
    </div>
  );
}
