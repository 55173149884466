import {
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getAllOrders } from '../api/orderApi';

function OrderPage() {
  const { data: orders } = useQuery(['order'], getAllOrders);
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Dashboard: Orders | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" gutterBottom>
          Orders
        </Typography>
        <Card>
          {orders && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell> Full Name</TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> Contact</TableCell>
                    <TableCell> Status</TableCell>
                    <TableCell>{''}</TableCell>
                  </TableHead>
                  <TableBody>
                    {orders.map((row) => {
                      const { _id, name, email, contact, status } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{contact}</TableCell>
                          <TableCell align="left">{status}</TableCell>
                          <TableCell align="right" width={'200px'}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                navigate(`/dashboard/order/${_id}`);
                              }}
                            >
                              See Order
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
export default OrderPage;
