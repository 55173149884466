import { Button, Container, FormControl, Stack, TextField, Typography } from '@mui/material';

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import SingleDropzone from '../components/dropzone/SingleDropzone';
import { uploadImage } from '../utils/uploadToFirebase';
import { createATestimonial } from '../api/testimonialApi';
import { startLoading, stopLoading } from '../redux/features/authSlice';

export default function NewTestimonial() {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [avatarFile, setAvatarFile] = useState();
  const [avatarPreview, setAvatarPreview] = useState();
  const [designation, setDesignation] = useState('');
  const [testimonial, setTestimonial] = useState('');

  const { mutate: addMutate } = useMutation(createATestimonial, {
    onSuccess: () => {
      dispatch(stopLoading());
      toast.success('Testimonial added successfully');
      setName('');
      setAvatarFile();
      setAvatarPreview();
      setDesignation('');
      setTestimonial('');
    },
    onError: () => {
      dispatch(stopLoading());
      toast.error('Error adding testimonial');
    },
  });

  const submitTestimonial = async (e) => {
    e.preventDefault();
    dispatch(startLoading());
    uploadImage({ items: avatarFile }).then((link) => {
      createPostRequest(link);
    });
  };

  const createPostRequest = (link) => {
    addMutate({ avatar: link, name, designation, testimonial });
  };

  return (
    <>
      <Helmet>
        <title>New Testimonial | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" gutterBottom>
          Create New Testimonial
        </Typography>

        <FormControl fullWidth>
          <Stack spacing={4}>
            <TextField label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
            <SingleDropzone
              setImageFile={setAvatarFile}
              setImagePreview={setAvatarPreview}
              imageFile={avatarFile}
              imagePreview={avatarPreview}
              title={'Avatar'}
            />
            <TextField
              label="Designation"
              variant="outlined"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
            <TextField
              label="Testimonial"
              variant="outlined"
              value={testimonial}
              onChange={(e) => setTestimonial(e.target.value)}
              multiline
              rows={4}
            />
            <div className="flex mt-4">
              <Button
                variant="contained"
                onClick={(e) => {
                  submitTestimonial(e);
                }}
              >
                Submit
              </Button>
            </div>
          </Stack>
        </FormControl>
      </Container>
      <Toaster />
    </>
  );
}
