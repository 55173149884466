import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3} {...other}>
      {products?.map((product) => (
        <Grid
          key={product._id}
          item
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            navigate(`/dashboard/products/${product._id}`);
          }}
          className="cursor-pointer"
        >
          <ShopProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
