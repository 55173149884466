import { axiosInstance } from '../configs/axiosConfig';

export const getAllBrands = async () => {
  const res = await axiosInstance.get('/brands');
  return res.data;
};

export const createNewBrand = async ({ label, value }) => {
  const res = await axiosInstance.post('/brands', { label, value });
};
