import { axiosInstance } from '../configs/axiosConfig';

export const getAllSpecs = async () => {
  const res = await axiosInstance.get('/specs');
  return res.data;
};

export const createASpec = async ({ title, category, specs }) => {
  await axiosInstance.post('/specs/', { title, category, specs });
};

export const getASingleSpec = async (id) => {
  const res = await axiosInstance.get(`/specs/${id}`);
  return res.data;
};
