import {
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { getAllInquiries } from '../api/inquiryApi';

export default function InquiryPage() {
  const { data: inquiries } = useQuery(['inquiry'], getAllInquiries);
  return (
    <>
      <Helmet>
        <title>Dashboard: Inquiries | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" gutterBottom>
          Inquiries
        </Typography>
        <Card>
          {inquiries && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableCell> Dealer Name</TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> Contact</TableCell>
                    <TableCell> Location</TableCell>
                    <TableCell> Message</TableCell>
                  </TableHead>
                  <TableBody>
                    {inquiries.map((row) => {
                      const { _id, dealerName, email, location, contact, message } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">{dealerName}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{contact}</TableCell>
                          <TableCell align="left">{location}</TableCell>
                          <TableCell align="left" width={'300px'}>
                            {message}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
