import {
  Container,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import SingleDropzone from '../components/dropzone/SingleDropzone';
import MultipleDropzone from '../components/dropzone/MultipleDropzone';
import ListInput from '../components/listinput/ListInput';
import { createNewCategory, getAllCatgories } from '../api/categoryApi';
import { createNewBrand, getAllBrands } from '../api/brandApi';
import { getASingleSpec, getAllSpecs } from '../api/specApi';
import { startLoading, stopLoading } from '../redux/features/authSlice';
import { uploadFiles, uploadImage } from '../utils/uploadToFirebase';
import { createAProduct } from '../api/productApi';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [category, setCategory] = useState('Select a Category');
  const [title, setTitle] = useState('');
  const [basePrice, setBasePrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [slug, setSlug] = useState('');
  const [metaDesc, setMetaDesc] = useState('');
  const [descPoints, setDescPoints] = useState([]);
  const [specsArray, setSpecsArray] = useState([]);
  const [brand, setBrand] = useState('Select a Brand');
  const [searchTags, setSearchTags] = useState([]);
  const [stockAmount, setStockAmount] = useState(0);
  const [coverImageFile, setCoverImageFile] = useState();
  const [coverImagePreview, setCoverImagePreview] = useState();
  const [imageVariationFiles, setImageVariationFiles] = useState([]);
  const [imageVariationPreviews, setImageVariationPreviews] = useState([]);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [specTitle, setSpecTitle] = useState('Select a Specification Template');

  const { data: categoryOptions } = useQuery(['category'], getAllCatgories);
  const { data: brandOptions } = useQuery(['brand'], getAllBrands);
  const { status, data: specOptions } = useQuery(['spec'], getAllSpecs);

  const { data: specs } = useQuery(['specs-attribute', specTitle], () => getASingleSpec(specTitle), {
    enabled: specTitle !== 'Select a Specification Template',
  });

  const { mutate } = useMutation(createAProduct, {
    onSuccess: () => {
      dispatch(stopLoading());
      toast.success('Sucessfully Created the product');
      setCategory('Select a Category');
      setTitle('');
      setSlug('');
      setBasePrice(0);
      setDiscount(0);
      setMetaDesc('');
      setStockAmount(0);
      setBrand('Select a Brand');
      setCoverImageFile();
      setCoverImagePreview();
      setImageVariationFiles([]);
      setImageVariationPreviews([]);
      setSpecTitle('Select a Specification Template');
      setSearchTags([]);
      setDescPoints([]);
      setSpecsArray([]);
    },
    onError: () => {
      console.log('error');
      dispatch(stopLoading());
      toast.error('Something went wrong please try again!');
    },
  });

  const InputFields = [
    {
      name: 'title',
      value: title,
      setValue: setTitle,
      label: 'Product Name',
      type: 'text',
      labelId: 'product-title',
    },
    {
      name: 'slug',
      value: slug,
      setValue: setSlug,
      label: 'Product Slug',
      type: 'text',
      labelId: 'product',
    },
    {
      name: 'basePrice',
      value: basePrice,
      setValue: setBasePrice,
      label: 'Product Price',
      type: 'number',
      labelId: 'product-price',
    },
    {
      name: 'discount',
      value: discount,
      setValue: setDiscount,
      label: 'Discount',
      type: 'number',
      labelId: 'product-price',
    },
    {
      name: 'metaDesc',
      value: metaDesc,
      setValue: setMetaDesc,
      label: 'Meta Description',
      type: 'text',
      labelId: 'product-meta',
    },
    {
      name: 'stock',
      value: stockAmount,
      setValue: setStockAmount,
      label: `Product's stock amount`,
      type: 'number',
      labelId: 'product-stock',
    },
  ];

  const handleCategoryClose = () => {
    setCategoryOpen(false);
  };

  const handleBrandClose = () => {
    setBrandOpen(false);
  };

  const submitProduct = async (e) => {
    dispatch(startLoading());
    uploadImage({ items: coverImageFile }).then((link) => {
      uploadFiles(imageVariationFiles).then(async (links) => {
        createPostRequest(links, link);
      });
    });
  };

  const createPostRequest = (links, link) => {
    const newArray = specsArray.map(({ _id, ...rest }) => rest);
    mutate({
      title,
      coverImage: link,
      imgVariations: links,
      slug,
      metaDesc,
      basePrice,
      discount,
      descPoints,
      specs: newArray,
      brand,
      searchTags,
      category,
      stockAmount,
    });
  };

  useEffect(() => {
    if (title !== '') {
      setSlug(title.toLowerCase().replace(/ /gi, '-'));
    }
  }, [title]);

  useEffect(() => {
    if (status === 'success') {
      console.log(specsArray);
      setSpecsArray(specs?.specs);
    }
  }, [specs?.specs, status]);

  return (
    <>
      <Helmet>
        <title>New Product | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create New Product
        </Typography>
        <div className="w-full">
          <Stack spacing={4}>
            <Stack spacing={2}>
              {/* <InputLabel id="product-category">Category of Product</InputLabel> */}
              <Select
                labelId="product-category"
                id="product-category"
                label="Age"
                value={category}
                defaultValue={'Select a Category'}
                onChange={(e) => {
                  if (e.target.value !== 'nochange') {
                    setCategory(e.target.value);
                  }
                }}
              >
                <MenuItem disabled value="Select a Category">
                  Select a Category
                </MenuItem>
                {categoryOptions?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value="nochange"
                  onClick={() => {
                    setCategoryOpen(true);
                  }}
                >
                  Create New Category
                </MenuItem>
              </Select>
            </Stack>
            {InputFields.map((item, index) => (
              <TextField
                label={item.label}
                name={item.name}
                value={item.value}
                type={item.type}
                onChange={(e) => item.setValue(e.target.value)}
                key={index}
              >
                {' '}
              </TextField>
            ))}
            <Stack spacing={2}>
              {/* <InputLabel id="product-brand">Brand of Product</InputLabel> */}
              <Select
                labelId="product-brand"
                id="product-brand"
                label="Brand"
                value={brand}
                defaultValue={'Select a Brand'}
                onChange={(e) => {
                  if (e.target.value !== 'nochange') {
                    setBrand(e.target.value);
                  }
                }}
              >
                <MenuItem disabled value="Select a Brand">
                  Select a Brand
                </MenuItem>
                {brandOptions?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value="nochange"
                  onClick={() => {
                    setBrandOpen(true);
                  }}
                >
                  Create New Brand
                </MenuItem>
              </Select>
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      console.log('This is good');
                    }}
                  />
                }
                label="Featured Product"
              />
            </FormGroup>
            <SingleDropzone
              setImageFile={setCoverImageFile}
              setImagePreview={setCoverImagePreview}
              imageFile={coverImageFile}
              imagePreview={coverImagePreview}
              title={'Cover Image'}
            />
            <MultipleDropzone
              setImageFiles={setImageVariationFiles}
              setImagePreviews={setImageVariationPreviews}
              imageFiles={imageVariationFiles}
              imagePreviews={imageVariationPreviews}
            />
            <ListInput label={'Description Points'} descPoints={descPoints} setDescPoints={setDescPoints} />

            {/* <InputLabel id="product-brand">Brand of Product</InputLabel> */}
            <Select
              labelId="product-spec"
              id="product-spec"
              label="Spec"
              value={specTitle}
              defaultValue={'Select a Specification Template'}
              onChange={(e) => {
                if (e.target.value !== 'nochange') {
                  setSpecsArray([]);
                  setSpecTitle(e.target.value);
                }
              }}
            >
              <MenuItem disabled value="Select a Specification Template">
                Select a Specification Table
              </MenuItem>
              {specOptions?.map((item, index) => (
                <MenuItem value={item._id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
              <MenuItem
                value="nochange"
                onClick={() => {
                  navigate('/dashboard/specs');
                }}
              >
                Create New Spec Template
              </MenuItem>
            </Select>
            {specs && (
              <div className="mb-6 w-full border-solid border border-[#00000025] border-b-0 rounded-md overflow-hidden">
                <div className="w-full flex bg-[#f9fafb] px-4  py-2 " style={{ borderBottom: '1px solid #00000025' }}>
                  <div className="flex-[3]">Attributes</div>
                  <div className="flex-[2]">Values</div>
                </div>
                <>
                  {specsArray?.map((item, index) => (
                    <div
                      key={index}
                      className="w-full flex bg-[#F7F7F7] px-4  py-2 "
                      style={{ borderBottom: '1px solid #00000025' }}
                    >
                      <p className="flex-[3]">{item.attribute}</p>
                      <div className="flex-[2]">
                        <TextField
                          className="w-full"
                          value={item.value}
                          onChange={(e) => {
                            const updatedSpecs = [...specsArray];
                            updatedSpecs[index].value = e.target.value;
                            setSpecsArray(updatedSpecs);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {/* {typeof descPoints} */}
                </>
              </div>
            )}

            <Autocomplete
              style={{ margin: '20px 0' }}
              multiple
              id="tags-outlined"
              options={['Laptop', 'Desktop', 'PC', 'IDK What']}
              defaultValue={['Laptop', 'Desktop', 'PC', 'IDK What']}
              freeSolo
              autoSelect
              onChange={(e) => setSearchTags(['Laptop', 'Desktop', 'PC', 'IDK What', e.target.value])}
              renderInput={(params) => (
                <TextField {...params} label="Search Tags" placeholder="Search Tags" value={searchTags} />
              )}
            />
          </Stack>
          <div className="mt-4">
            <Button
              variant="contained"
              onClick={() => {
                submitProduct();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        <NewCategoryDialog open={categoryOpen} handleClose={handleCategoryClose} />
        <NewBrandDialog open={brandOpen} handleClose={handleBrandClose} />
      </Container>
      <Toaster />
    </>
  );
}

function NewCategoryDialog({ open, handleClose }) {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const queryClient = useQueryClient();

  const { mutate: addCategoryMutate } = useMutation(createNewCategory, {
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries(['category']);
    },
  });

  const handleSubmit = () => {
    addCategoryMutate({ label, value });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-new-category"
    >
      <DialogTitle>{'Create a New Category'}</DialogTitle>
      <DialogContent id="alert-dialog-new-category">
        <Stack spacing={2} direction={'column'}>
          <TextField
            label={'Label for Category'}
            name={'label'}
            value={label}
            type={'text'}
            onChange={(e) => setLabel(e.target.value)}
            sx={{ mt: 1 }}
          >
            {' '}
          </TextField>
          <TextField
            label={'Value for Category'}
            name={'value'}
            value={value}
            type={'text'}
            onChange={(e) => setValue(e.target.value)}
          >
            {' '}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function NewBrandDialog({ open, handleClose }) {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const queryClient = useQueryClient();

  const { mutate: addBrandMutate } = useMutation(createNewBrand, {
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries(['brand']);
    },
  });

  const handleSubmit = () => {
    addBrandMutate({ label, value });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-new-category"
    >
      <DialogTitle>{'Create a New Brand'}</DialogTitle>
      <DialogContent id="alert-dialog-new-brand">
        <Stack spacing={2} direction={'column'}>
          <TextField
            label={'Label for Brand'}
            name={'label'}
            value={label}
            type={'text'}
            onChange={(e) => setLabel(e.target.value)}
            sx={{ mt: 1 }}
          >
            {' '}
          </TextField>
          <TextField
            label={'Value for Brand'}
            name={'value'}
            value={value}
            type={'text'}
            onChange={(e) => setValue(e.target.value)}
          >
            {' '}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
