import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from '@tanstack/react-query';

import { createASpec } from '../api/specApi';
import { createNewCategory, getAllCatgories } from '../api/categoryApi';
import TableInput from '../components/table-input/TableInput';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewSpec() {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [specs, setSpecs] = useState([
    { attribute: '', value: '' },
    // Add more initial rows if needed
  ]);
  const [open, setOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const { data: categoryOptions } = useQuery(['category'], getAllCatgories);

  const { mutate: addMutate } = useMutation(createASpec, {
    onSuccess: () => {
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
    // console.log(specs);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    addMutate({ title, category, specs });
  };

  const handleCategoryClose = () => {
    setCategoryOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>New Spec | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Create New Specs Table
        </Typography>
        <FormControl fullWidth>
          <Stack spacing={4}>
            <TextField
              label={'Table Title'}
              name={'tableTitle'}
              value={title}
              type={'text'}
              onChange={(e) => setTitle(e.target.value)}
            >
              {' '}
            </TextField>
            <Stack spacing={2}>
              {/* <InputLabel id="product-category">Category of Product</InputLabel> */}
              <Select
                labelId="product-category"
                id="product-category"
                label="Age"
                value={category}
                defaultValue={'Select a Category'}
                onChange={(e) => {
                  if (e.target.value !== 'nochange') {
                    setCategory(e.target.value);
                  }
                }}
              >
                <MenuItem disabled value="Select a Category">
                  Select a Category
                </MenuItem>
                {categoryOptions?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value="nochange"
                  onClick={() => {
                    setCategoryOpen(true);
                  }}
                >
                  Create New Category
                </MenuItem>
              </Select>
            </Stack>

            <TableInput label={'Table Input'} specs={specs} setSpecs={setSpecs} />
          </Stack>
          <div className="flex mt-4">
            <Button
              variant="contained"
              onClick={() => {
                handleClickOpen();
              }}
            >
              Submit
            </Button>
          </div>
        </FormControl>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{'You want to Save this table?'}</DialogTitle>
          <DialogContent id="alert-dialog-slide-description">
            <TableContainer sx={{ minWidth: 300 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Attributes</TableCell>
                    <TableCell align="right">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specs.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.attribute}</TableCell>
                      <TableCell>{item.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit}>Save</Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <NewCategoryDialog open={categoryOpen} handleClose={handleCategoryClose} />
      </Container>
    </>
  );
}

function NewCategoryDialog({ open, handleClose }) {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');

  const { mutate: addCategoryMutate } = useMutation(createNewCategory, {
    onSuccess: () => {
      handleClose();
    },
  });

  const handleSubmit = () => {
    addCategoryMutate({ label, value });
  };

  const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-new-category"
    >
      <DialogTitle>{'Create a New Category'}</DialogTitle>
      <DialogContent id="alert-dialog-new-category">
        <Stack spacing={2} direction={'column'}>
          <TextField
            label={'Label for Category'}
            name={'label'}
            value={label}
            type={'text'}
            onChange={(e) => setLabel(e.target.value)}
            sx={{ mt: 1 }}
          >
            {' '}
          </TextField>
          <TextField
            label={'Value for Category'}
            name={'value'}
            value={value}
            type={'text'}
            onChange={(e) => setValue(e.target.value)}
          >
            {' '}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Save</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
