import { axiosInstance } from '../configs/axiosConfig';

export const getAllProducts = async () => {
  const res = await axiosInstance.get('/products');
  return res.data;
};

export const createAProduct = async ({
  title,
  coverImage,
  imgVariations,
  slug,
  metaDesc,
  basePrice,
  discount,
  descPoints,
  specs,
  brand,
  searchTags,
  category,
  stockAmount,
}) => {
  const res = await axiosInstance.post('/products', {
    title,
    coverImage,
    imgVariations,
    slug,
    metaDesc,
    basePrice,
    discount,
    descPoints,
    specs,
    brand,
    searchTags,
    category,
    stockAmount,
  });
};

export const getASingleProduct = async (id) => {
  const res = await axiosInstance.get(`/products/${id}`);
  return res.data;
};

export const updateAProduct = async ({
  productId,
  title,
  coverImage,
  imgVariations,
  slug,
  metaDesc,
  basePrice,
  discount,
  descPoints,
  specs,
  brand,
  searchTags,
  category,
  stockAmount,
}) => {
  console.log({
    productId,
    title,
    coverImage,
    imgVariations,
    slug,
    metaDesc,
    basePrice,
    discount,
    descPoints,
    specs,
    brand,
    searchTags,
    category,
    stockAmount,
  });
  const res = await axiosInstance.put(`/products/${productId}`, {
    title,
    coverImage,
    imgVariations,
    slug,
    metaDesc,
    basePrice,
    discount,
    descPoints,
    specs,
    brand,
    searchTags,
    category,
    stockAmount,
  });
};

export const deleteAProduct = async (id) => {
  const res = await axiosInstance.delete(`/products/${id}`);
};
