import { axiosInstance } from '../configs/axiosConfig';

export const getAllOrders = async () => {
  const res = await axiosInstance.get('/orders/');
  return res.data;
};

export const getASingleOrder = async (id) => {
  const res = await axiosInstance.get(`/orders/${id}`);
  return res.data;
};
