export const StateOptions = [
  {
    label: 'Koshi',
    value: 'Koshi',
  },
  {
    label: 'Madhesh',
    value: 'Madhesh',
  },
  {
    label: 'Bagmati',
    value: 'Bagmati',
  },
  {
    label: 'Gandaki',
    value: 'Gandaki',
  },
  {
    label: 'Lumbini',
    value: 'Lumbini',
  },
  {
    label: 'Karnali',
    value: 'Karnali',
  },
  {
    label: 'Sudurpaschim',
    value: 'Sudurpaschim',
  },
];

export const nepalDistricts = [
  { label: 'Achham', value: 'Achham' },
  { label: 'Arghakhanchi', value: 'Arghakhanchi' },
  { label: 'Baglung', value: 'Baglung' },
  { label: 'Baitadi', value: 'Baitadi' },
  { label: 'Bajhang', value: 'Bajhang' },
  { label: 'Bajura', value: 'Bajura' },
  { label: 'Banke', value: 'Banke' },
  { label: 'Bara', value: 'Bara' },
  { label: 'Bardiya', value: 'Bardiya' },
  { label: 'Bhaktapur', value: 'Bhaktapur' },
  { label: 'Bhojpur', value: 'Bhojpur' },
  { label: 'Chitwan', value: 'Chitwan' },
  { label: 'Dadeldhura', value: 'Dadeldhura' },
  { label: 'Dailekh', value: 'Dailekh' },
  { label: 'Dang', value: 'Dang' },
  { label: 'Darchula', value: 'Darchula' },
  { label: 'Dhading', value: 'Dhading' },
  { label: 'Dhankuta', value: 'Dhankuta' },
  { label: 'Dhanusa', value: 'Dhanusa' },
  { label: 'Dholkha', value: 'Dholkha' },
  { label: 'Dolpa', value: 'Dolpa' },
  { label: 'Doti', value: 'Doti' },
  { label: 'Gorkha', value: 'Gorkha' },
  { label: 'Gulmi', value: 'Gulmi' },
  { label: 'Humla', value: 'Humla' },
  { label: 'Ilam', value: 'Ilam' },
  { label: 'Jajarkot', value: 'Jajarkot' },
  { label: 'Jhapa', value: 'Jhapa' },
  { label: 'Jumla', value: 'Jumla' },
  { label: 'Kailali', value: 'Kailali' },
  { label: 'Kalikot', value: 'Kalikot' },
  { label: 'Kanchanpur', value: 'Kanchanpur' },
  { label: 'Kapilvastu', value: 'Kapilvastu' },
  { label: 'Kaski', value: 'Kaski' },
  { label: 'Kathmandu', value: 'Kathmandu' },
  { label: 'Kavrepalanchok', value: 'Kavrepalanchok' },
  { label: 'Khotang', value: 'Khotang' },
  { label: 'Lalitpur', value: 'Lalitpur' },
  { label: 'Lamjung', value: 'Lamjung' },
  { label: 'Mahottari', value: 'Mahottari' },
  { label: 'Makwanpur', value: 'Makwanpur' },
  { label: 'Manang', value: 'Manang' },
  { label: 'Morang', value: 'Morang' },
  { label: 'Mugu', value: 'Mugu' },
  { label: 'Mustang', value: 'Mustang' },
  { label: 'Myagdi', value: 'Myagdi' },
  { label: 'Nawalparasi', value: 'Nawalparasi' },
  { label: 'Nuwakot', value: 'Nuwakot' },
  { label: 'Okhaldhunga', value: 'Okhaldhunga' },
  { label: 'Palpa', value: 'Palpa' },
  { label: 'Panchthar', value: 'Panchthar' },
  { label: 'Parbat', value: 'Parbat' },
  { label: 'Parsa', value: 'Parsa' },
  { label: 'Pyuthan', value: 'Pyuthan' },
  { label: 'Ramechhap', value: 'Ramechhap' },
  { label: 'Rasuwa', value: 'Rasuwa' },
  { label: 'Rautahat', value: 'Rautahat' },
  { label: 'Rolpa', value: 'Rolpa' },
  { label: 'Rukum', value: 'Rukum' },
  { label: 'Rupandehi', value: 'Rupandehi' },
  { label: 'Salyan', value: 'Salyan' },
  { label: 'Sankhuwasabha', value: 'Sankhuwasabha' },
  { label: 'Saptari', value: 'Saptari' },
  { label: 'Sarlahi', value: 'Sarlahi' },
  { label: 'Sindhuli', value: 'Sindhuli' },
  { label: 'Sindhupalchok', value: 'Sindhupalchok' },
  { label: 'Siraha', value: 'Siraha' },
  { label: 'Solukhumbu', value: 'Solukhumbu' },
  { label: 'Sunsari', value: 'Sunsari' },
  { label: 'Surkhet', value: 'Surkhet' },
  { label: 'Syangja', value: 'Syangja' },
  { label: 'Tanahu', value: 'Tanahu' },
  { label: 'Taplejung', value: 'Taplejung' },
  { label: 'Terhathum', value: 'Terhathum' },
  { label: 'Udayapur', value: 'Udayapur' },
];
