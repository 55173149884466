import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteATestimonial } from '../../api/testimonialApi';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteTestimonial({ setActive, active, testimonialId, testimonialName }) {
  const ModalRef = useRef(null);
  const navigate = useNavigate();

  const handleClose = () => {
    setActive(false);
  };

  const { mutate: deleteMutate } = useMutation(deleteATestimonial, {
    onSuccess: () => {
      //   toast.success('Successfully deleted the product');
      navigate('/dashboard/testimonials');
    },
  });

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActive(false);
        }
      }
      document.addEventListener('mousedown', (e) => handleClickOutside(e));

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ModalRef);

  return (
    <Dialog
      open={active}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{'Delete this Testimonial?'}</DialogTitle>
      <DialogContent id="alert-dialog-slide-description">
        Do you really want to delete testimonial from {testimonialName}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleteMutate(testimonialId);
          }}
          sx={{ color: 'red' }}
        >
          {' '}
          Delete
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
