import { axiosInstance } from '../configs/axiosConfig';

export const getAllDealers = async () => {
  const res = await axiosInstance.get('/dealers');
  return res.data;
};

export const createANewDealer = async ({
  name,
  email,
  phone,
  state,
  district,
  city,
  address,
  hasPNY,
  hasHuntkey,
  hasHavit,
}) => {
  await axiosInstance.post('/dealers/', {
    name,
    email,
    phone,
    state,
    district,
    city,
    address,
    hasPNY,
    hasHuntkey,
    hasHavit,
  });
};

export const getASingleDealer = async (id) => {
  const res = await axiosInstance.get(`/dealers/${id}`);
  return res.data;
};

export const deleteADealer = async (id) => {
  const res = await axiosInstance.delete(`/dealers/${id}`);
};
