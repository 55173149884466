import { axiosInstance } from '../configs/axiosConfig';

export const getAllCatgories = async () => {
  const res = await axiosInstance.get('/categories');
  return res.data;
};

export const createNewCategory = async ({ label, value }) => {
  const res = await axiosInstance.post('/categories', { label, value });
};
