import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment/moment';
import { getASingleOrder } from '../api/orderApi';
import { ProductCard } from '../sections/@dashboard/products';

function SingleOrder() {
  const location = useLocation();
  const id = location.pathname.split('/')[3];

  const { data: order } = useQuery(['single-order', id], () => getASingleOrder(id));
  console.log(order);
  return (
    <>
      <Helmet>
        <title>Dashboard: Order | iTeam</title>
      </Helmet>
      <Container>
        <Typography variant="h4" gutterBottom className="mb-10">
          Order ID: {order?._id}
        </Typography>
        <Stack spacing={4} direction={'column'}>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">Purchaser :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {order?.name}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">Orderd Date :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {moment(order?.createdAt).format('L')}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">Email :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {order?.email}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">Contact :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {order?.contact}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">State :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {order?.state}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">District :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {order?.district}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">Municipality :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {order?.muni}
            </Typography>
          </Stack>
          <Stack direction={'column'}>
            <Typography variant="h6">Products</Typography>
            <Grid container spacing={3}>
              {order?.products?.map((item, index) => {
                const prod = {
                  title: item.title,
                  coverImage: item.imgUrl,
                  basePrice: item.price,
                  quantity: item.quantity,
                };
                return (
                  <Grid key={index} item xs={12} sm={6} md={3} className="cursor-pointer">
                    <ProductCard product={prod} />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export default SingleOrder;
