import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { getASingleTestimonial } from '../api/testimonialApi';
import DeleteTestimonial from '../components/testimonials/DeleteTestimonials';

export default function SingleTestimonials() {
  const location = useLocation();
  const testimonialId = location.pathname.split('/')[3];
  const [deleteActive, setDeleteActive] = useState(false);

  const { data: testimonial } = useQuery(['single-testimonial', testimonialId], () =>
    getASingleTestimonial(testimonialId)
  );

  return (
    <>
      <Helmet>
        <title>Single Testimonial | iTeam</title>
      </Helmet>
      <Container>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            {testimonial?.name}
          </Typography>
          <Button variant="contained" sx={{ background: 'red' }} onClick={() => setDeleteActive(true)}>
            Delete Testimonial
          </Button>
        </Stack>
        <Stack direction={'column'} spacing={4}>
          <div className="w-[30%] aspect-square">
            <img src={testimonial?.avatar} alt={testimonial?.name} className="object-cover w-full h-full" />
          </div>
          <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
            <Typography variant="h6">Designation :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {testimonial?.designation}
            </Typography>
          </Stack>
          <Stack spacing={2} direction={'column'} flex alignItems={'start'}>
            <Typography variant="h6">Testimonial :</Typography>
            <Typography variant="body2" sx={{ fontSize: 16 }}>
              {testimonial?.testimonial}
            </Typography>
          </Stack>
        </Stack>
        <DeleteTestimonial
          active={deleteActive}
          setActive={setDeleteActive}
          testimonialId={testimonialId}
          testimonialName={testimonial?.name}
        />
      </Container>
    </>
  );
}
