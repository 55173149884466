import {
  Button,
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { filter } from 'lodash';
import Iconify from '../components/iconify/Iconify';
import { deleteADealer, getAllDealers } from '../api/dealerApi';
import Scrollbar from '../components/scrollbar/Scrollbar';
import DealerHead from '../sections/@dashboard/dealers/DealerHead';

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array && array.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Dealer() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteMutate } = useMutation(deleteADealer, {
    onSuccess: () => {
      queryClient.invalidateQueries(['dealers']);
    },
  });

  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data: dealers } = useQuery(['dealers'], getAllDealers);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dealers?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const filteredUsers = applySortFilter(dealers, getComparator(order, orderBy), filterName);

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'state', label: 'State', alignRight: false },
    { id: 'district', label: 'District', alignRight: false },
    { id: 'city', label: 'City', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: '', alignRight: true },
  ];

  return (
    <>
      <Helmet>
        <title>Dashboard: Dealers Table | iTeam</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Dealers
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate('/dashboard/new-dealer')}
          >
            New Dealer
          </Button>
        </Stack>

        <Card>
          {dealers && (
            <>
              <Scrollbar>
                <TableContainer>
                  <Table>
                    <DealerHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={filteredUsers.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { _id, name, email, phone, state, district, city, address } = row;
                        const selectedUser = selected.indexOf(name) !== -1;

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                            </TableCell>

                            <TableCell align="left">{name}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">{state}</TableCell>
                            <TableCell align="left">{district}</TableCell>
                            <TableCell align="left">{city}</TableCell>
                            <TableCell align="left">{address}</TableCell>

                            {/* <TableCell align="left">{role}</TableCell>
  
                          <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>
  
                          <TableCell align="left">
                            <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                          </TableCell>
  
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell> */}
                            <TableCell align="right">
                              <Button
                                variant="contained"
                                className="text-warning bg-red-600 hover:bg-red-700"
                                onClick={
                                  () => {
                                    deleteMutate(_id);
                                  }
                                  //   navigate(`/dashboard/specs/${name.toLowerCase().replace(' ', '_')}`, {
                                  //     state: {
                                  //       id: _id,
                                  //     },
                                  //   })
                                }
                              >
                                Delete Dealer
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
