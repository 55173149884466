import {
  Box,
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import EditProduct from '../components/edit-product/EditProduct';
import { getASingleProduct } from '../api/productApi';
import DeletePopup from '../components/delete-popup/DeletePopup';

export default function SingleProduct() {
  const id = useLocation().pathname.split('/')[3];
  const { data: product } = useQuery(['single-spec', id], () => getASingleProduct(id));

  const [editProduct, setEditProduct] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);

  const enableEdit = () => {
    setEditProduct(true);
  };

  return (
    <>
      <Helmet>
        <title>Single Product | iTeam | Dahsboard</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {product?.title}
        </Typography>
        {editProduct ? (
          <EditProduct product={product} setActive={setEditProduct} />
        ) : (
          <>
            <div className="w-full flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant="contained"
                  sx={{ background: 'red' }}
                  onClick={() => {
                    // deleteMutate(product?._id);
                    setDeleteActive(true);
                  }}
                >
                  Delete Product
                </Button>
                <Button variant="contained" onClick={enableEdit}>
                  Edit Product
                </Button>
              </div>
            </div>
            <Stack direction={'column'} spacing={4}>
              <div className="w-[30%] aspect-square">
                <img
                  src={product?.coverImage}
                  alt={product?.title}
                  className="w-full h-full object-cover object-top rounded-2xl"
                />
              </div>
              <div className="w-full flex gap-2">
                {product?.imgVariations?.map((item, index) => (
                  <div key={index} className="w-[10%] aspect-square">
                    <img src={item} alt="iteam-product" className="w-full h-full object-cover object-top rounded-md" />
                  </div>
                ))}
              </div>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Category :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.category}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Price :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.basePrice}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Discount :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.discount}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Meta Description :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.metaDesc}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Stock Amount :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.stockAmount}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Brand :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.brand}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} flex alignItems={'center'}>
                <Typography variant="h6">Slug :</Typography>
                <Typography variant="body2" sx={{ fontSize: 16 }}>
                  {product?.slug}
                </Typography>
              </Stack>
              <div>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Description Points
                </Typography>
                <Box>
                  <ul className=" px-4 ">
                    {product?.descPoints?.map((item, index) => (
                      <li className="flex items-center mb-2" key={index}>
                        <Typography variant="body2" sx={{ fontSize: 16 }}>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Search Tags
                </Typography>
                <div className="flex gap-4">
                  {product?.searchTags?.length > 0 ? (
                    product?.searchTags?.map((item, index) => (
                      <div key={index} className="bg-[#e8ebee] px-4 py-2 rounded-2xl">
                        {item}
                      </div>
                    ))
                  ) : (
                    <div className="flex">
                      <div className="bg-[#e8ebee] px-4 py-2 rounded-2xl">No tags found </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Specification Table
                </Typography>
                {product?.specs ? (
                  <TableContainer sx={{ minWidth: 300 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Attributes</TableCell>
                          <TableCell align="right">Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {product?.specs?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.attribute}</TableCell>
                            <TableCell>{item.value}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div>Could not find a spec table</div>
                )}
              </div>
            </Stack>
          </>
        )}
        <DeletePopup
          active={deleteActive}
          setActive={setDeleteActive}
          productId={product?._id}
          productTitle={product?.title}
        />
      </Container>
    </>
  );
}
