import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

export default function TableInput({ label, specs, setSpecs }) {
  const handleAddRow = () => {
    setSpecs([...specs, { attribute: '', value: '' }]);
  };

  const handleChange = (event, index, key) => {
    const updatedSpecs = [...specs];
    updatedSpecs[index][key] = event.target.value;
    setSpecs(updatedSpecs);
  };
  return (
    <div>
      <div className="flex gap-10 items-center ">
        <Typography variant="h6" sx={{ mb: 2 }}>
          {label}
        </Typography>
        <Button variant="contained" onClick={handleAddRow}>
          Add Row
        </Button>
      </div>
      <TableContainer sx={{ minWidth: 300 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attribute</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specs.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={row.attribute}
                    onChange={(event) => handleChange(event, index, 'attribute')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={row.value}
                    onChange={(event) => handleChange(event, index, 'value')}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
