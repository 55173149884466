import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import NewProduct from './pages/NewProduct';
import SpecTable from './pages/SpecTable';
import NewSpec from './pages/NewSpec';
import SingleSpec from './pages/SingleSpec';
import SingleProduct from './pages/SingleProduct';
import InquiryPage from './pages/InquiryPage';
import OrderPage from './pages/OrderPage';
import SingleOrder from './pages/SingleOrder';
import TestimonialTable from './pages/Testimonial';
import NewTestimonial from './pages/NewTestimonial';
import SingleTestimonials from './pages/SingleTestimonials';
import HeroImage from './pages/HeroImage';
import NewHeroImage from './pages/NewHeroImage';
import Dealers from './pages/Dealers';
import NewDealer from './pages/NewDealer';
import SingleDealer from './pages/SingleDealer';

// ----------------------------------------------------------------------

export default function Router() {
  const hasUser = useSelector((state) => state.auth.isLoggedIn);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: hasUser ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'new-product', element: <NewProduct /> },
        { path: 'specs', element: <SpecTable /> },
        { path: 'new-specs', element: <NewSpec /> },
        { path: 'specs/:id', element: <SingleSpec /> },
        { path: 'testimonials', element: <TestimonialTable /> },
        { path: 'new-testimonial', element: <NewTestimonial /> },
        { path: 'testimonials/:id', element: <SingleTestimonials /> },
        { path: 'hero-image', element: <HeroImage /> },
        { path: 'new-hero-image', element: <NewHeroImage /> },
        { path: 'dealers', element: <Dealers /> },
        { path: 'new-dealer', element: <NewDealer /> },
        { path: 'dealers/:id', element: <SingleDealer /> },
        { path: 'products/:id', element: <SingleProduct /> },
        { path: 'inquiry', element: <InquiryPage /> },
        { path: 'order', element: <OrderPage /> },
        { path: 'order/:id', element: <SingleOrder /> },
      ],
    },
    {
      path: 'login',
      element: hasUser ? <Navigate to="/dashboard/app" /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
