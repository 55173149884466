import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 2022 1600">
        <path
          fill="#09C"
          d="M467.7 201c-23.5 3.7-45.5 13.6-64 28.8-22.6 18.4-37.9 44.4-44.3 75.3-2.2 10.3-2.5 34.9-.5 45.9 3.1 17.4 9.9 34.5 19.7 49.2 20 30.1 50.8 50.2 86.4 56.4 14.1 2.4 39.1 1.5 52.1-1.9 24.3-6.4 44.2-17.7 61.2-34.8 3.7-3.7 6.7-7.1 6.7-7.6 0-.4-2.5 1.9-5.5 5.2-3 3.3-5.4 5.6-5.5 5.1 0-.5 6.7-33.9 15-74.3l15-73.4-2.7-5.2c-13-25.9-38-48.8-65.5-60.1-15.5-6.4-27.1-8.7-45.8-9.1-9.1-.2-19.1 0-22.3.5zM605 280c.6 1.1 1.3 2 1.6 2 .2 0 0-.9-.6-2s-1.3-2-1.6-2c-.2 0 0 .9.6 2zm9.1 36.2c0 .3-3.5 17.2-7.6 37.4s-7.3 36.9-7.1 37.2c.6.6 5.4-9 8.3-16.7 5.6-15 9.1-39.4 7.4-52.1-.5-3.6-.9-6.2-1-5.8zM592.5 401c-.3.5-.1 1 .4 1 .6 0 1.1-.5 1.1-1 0-.6-.2-1-.4-1-.3 0-.8.4-1.1 1zm-5.5 8.2c-1.2 2.2-1.2 2.2.6.6 1-1 1.5-2 1.2-2.4-.4-.3-1.2.5-1.8 1.8zM458.6 514.6c-37.7 5.1-72.5 22.8-98.6 50.1-23.7 24.8-37.6 51.9-44.7 87.3-1.6 7.9-1.7 31-2.3 311.5-.5 284.7-.6 303.4-2.3 309-4.5 15.5-11.9 29.2-21.5 39.5-16.5 18-37.7 27.8-63.4 29.6l-9.8.7v37.9c0 33.8.2 37.9 1.6 38.5 2.9 1.1 29.6-1.7 41.4-4.3 60.2-13.4 108.4-59.2 124.6-118.4 6.2-22.4 5.7 2.9 6.3-328.5l.6-303 2.3-7.3c10.2-33.1 34.6-56.8 66.9-64.9 5.2-1.3 10.9-1.7 24.3-1.7 16.1 0 18.2.3 26.3 2.7 9.1 2.8 19.4 7.6 26 12.1 3.4 2.3 5.7 3.2 5.7 2.3 0-.5 14.8-73.3 15.5-76.3.4-1.8-.4-2.5-4.8-4.4-27.8-12-63-16.7-94.1-12.4zm111.9 23.6c-.2.7-3.8 18.2-8 38.8-4.2 20.6-7.9 38.6-8.4 40-.6 2 .2 3.7 4.6 10 9.7 13.4 15.3 27.9 17.3 44 .6 5.4 1 66.1 1 169.6 0 135.2.2 162.4 1.4 168.6 8.4 43.9 42.1 77.7 86.2 86.5 7.4 1.4 14.5 1.7 40.9 1.8h32l.3-38.2.2-38.2-30.2-.3-30.3-.3-5.6-2.8c-6.6-3.2-13.2-9.9-16.2-16.5l-2.2-4.7-.6-169c-.6-184.9-.2-172.4-6.4-193.9-10.6-36.7-34.5-69.7-66.1-91.1-8.6-5.9-9.2-6.2-9.9-4.3zM688 653.5V688h79v410l37.3-.2 37.2-.3.3-204.8.2-204.7h78v-69H688v34.5zm273.3-33.8c2.6.2 6.7.2 9 0s.2-.3-4.8-.3c-4.9 0-6.8.1-4.2.3z"
        />
        <path
          fill="#39C"
          d="M603.5 277.2c-1 3.3-29.6 145.1-29.2 145.4.2.3 3.1-2.6 6.3-6.2 3.2-3.7 7-7.8 8.3-9.1 1.3-1.2 2-2.3 1.5-2.3s-.2-.5.6-1 1.5-1.4 1.5-2c0-.6.7-1.5 1.5-2s1.2-1 .8-1c-.5 0 .1-.9 1.2-2 1.2-1.2 1.8-2.5 1.5-2.9-.2-.5.2-1.2 1.1-1.5.8-.3 1.3-1 1-1.5-.3-.5 2.8-17.5 7-37.8 4.2-20.2 7.6-37.7 7.6-38.8-.1-6.2-1.3-14-2.2-14.5-.6-.4-.8-1.4-.4-2.3.4-1 .1-1.7-.6-1.7s-1-.7-.6-1.5c.3-.8.1-1.5-.3-1.5-.5 0-.8-.8-.6-1.8.1-.9-.2-2.3-.8-3-.5-.6-.6-1.2-.2-1.2.5 0 0-.9-1-2s-1.5-2-1.1-2c.4 0 0-1-.8-2.1-.8-1.2-1.2-2.8-.9-3.5.3-.8.2-1.4-.1-1.4-.4 0-.8.6-1.1 1.2zm-46 254c-.2.7-3.6 17.3-7.5 36.8-3.9 19.5-7.5 36.8-8 38.3-.8 2.5-.7 3 1 3.4 1.1.3 1.7.9 1.4 1.4-.3.5 0 .9.7.9.6 0 2.7 1.7 4.4 3.7 1.8 2.1 3.6 3.5 3.9 3.2.4-.4 4.2-18.1 8.6-39.5 4.4-21.4 8.2-39.9 8.5-41.2.3-1.2.1-2.2-.6-2.2-.6 0-1.7-.7-2.6-1.5-.8-.8-1.9-1.3-2.4-1-.5.4-.9.2-.9-.3 0-.8-4.2-3.2-5.6-3.2-.2 0-.6.6-.9 1.2z"
        />
        <path
          fill="#06C"
          d="m965.2 619.7-9.2.4.2 238.7.3 238.7 17.5-.4c9.6-.2 17.6-.4 17.7-.5.2-.2 8.9-42.7 19.5-94.7 10.6-51.9 19.6-94.6 20-94.7.4-.2.8-6 .8-12.8 0-11.4.2-12.4 1.8-12.4s2.7-4.1 8.5-32.8c3.7-18 6.7-33.3 6.7-34 0-.9-2.4-1.2-8.5-1.2h-8.5V688l21.7-.2 21.6-.3 6.8-33c3.8-18.2 6.8-33.6 6.9-34.3 0-.9-12-1.2-57.2-1-31.5.1-61.5.4-66.6.5z"
        />
        <path
          fill="#069"
          d="M1089 619.9c0 .4-3.1 16-6.9 34.5l-6.9 33.6h85.8v-69h-36c-19.8 0-36 .4-36 .9zm173.6-.1c-.6 1.1-77.6 473.3-77.6 476.2 0 2 .6 2 33.9 2 25.9 0 34.1-.3 34.8-1.3.5-.6 3.8-21 7.3-45.2 3.5-24.2 6.5-44.8 6.8-45.7.4-1.7 3-1.8 43-1.6l42.6.3 6.6 45.5c3.6 25 6.9 46.1 7.3 46.7.6 1 9.2 1.3 37.2 1.3 20 0 36.6-.4 36.9-.8.4-.6-75.2-468.3-76.9-476l-.5-2.2h-50.5c-27.7 0-50.6.4-50.9.8zm48.4 83.8c0 .4 7.4 53 16.5 117S1344 937.4 1344 938c0 .6-11.8 1-33.6 1h-33.7l.5-3.3c.3-1.7 7.7-54.8 16.4-118 8.7-63.1 16.2-114.7 16.6-114.7.5 0 .8.3.8.6zm167.2 154.6.3 239.3h65l.5-181c.3-99.6.6-180.8.8-180.5.1.3 12.5 81.7 27.5 181l27.3 180.5h62.9l26.7-176.5c14.6-97.1 27-178.5 27.5-181 .4-2.6 1 39.7 1.3 101.1l.5 105.6 32.8-161.1c18.1-88.6 33.1-162.3 33.4-163.9l.5-2.7h-103l-1 7.2c-.6 4-11.3 80.9-23.8 170.8s-22.9 163.7-23.1 163.9c-.2.2-.5.2-.8 0-.2-.3-11.2-77.2-24.3-171l-23.8-170.4-53.7-.3-53.7-.2.2 239.2zm-435-12.9c-3.5 17.1-6.6 32.4-6.9 33.9l-.5 2.8h99.2v-68h-85.5l-6.3 31.3zm-31.1 152.5c-10.2 49.9-18.9 92.6-19.4 94.9l-.8 4.2-11.7.4c-6.4.1 31.6.4 84.6.5l96.2.2v-69h-129v-61c0-33.6-.3-61-.7-61-.5 0-9.1 40.8-19.2 90.8z"
        />
        <path
          fill="#039"
          d="M1785 619.8c0 .4-15.1 74.7-33.5 165L1718 949.1l.2 74.2.3 74.2 35.3.3 35.2.2V858.5c0-238.8 0-239.5-2-239.5-1.1 0-2 .3-2 .8z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
